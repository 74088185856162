<template>
    <div class="moremessage-page">
        <div v-if="info">
            <van-cell-group>
                <van-cell title="订单状态">
                    <div slot="default">
                        <div class="left"></div>
                        <div class="right text-theme3">{{info.pay_status_note}}</div>
                    </div>
                </van-cell>
                <van-cell title="创建时间">
                    <div slot="default">
                        <div class="left">{{info.createtime}}</div>
                    </div>
                </van-cell>
                <van-cell title="报名信息">
                    <div slot="default">
                        <div class="left">{{info.linkman+'('+info.phone+')'}}</div>
                    </div>
                </van-cell>
                <van-cell title="门票种类">
                    <div slot="default">
                        <div class="left">{{info.card_info.card_name}}</div>
                        <div class="right text-theme3">￥{{info.card_info.amount==0 ? "免费" : info.card_info.amount}}
                        </div>
                    </div>
                </van-cell>
                <van-cell title="电子票" is-link :to="{path:'/activity/enroll/ticket',query:{id:info.id}}" v-if="info.pay_status>0"/>
                <van-cell title="我的订单" is-link :to="{path:'/activity/enroll/myorder',query:{id:info.activity_id}}"/>
                <van-cell title="我的积分" is-link v-show="activity.is_score_switch > 0" :to="{path:'/activity/marketing/bean/scoreDetail',query:{activity_id:info.activity_id}}"/>
				<van-cell title="备注" value-class="flex-5">
                    <div slot="default">
                        <div class="left">{{info.note}}</div>
                    </div>
                </van-cell>
            </van-cell-group>


            <div style="padding: 0.5rem" v-if="info.is_free!=true&&info.pay_status==0">
                <van-button type="info" class="mr10" block @click="toPay">去支付</van-button>
            </div>

            <!-- 活动 -->
            <van-cell-group>
                <van-cell value="查看活动" is-link class="cell-top" value-class="text-theme2 font-16"
                          @click="toActivity()">
                    <template slot="title">
                        <van-image
                                round
                                width="0.8rem"
                                height="0.8rem"
                                :src="info.banner_image"
                                fit="cover"
                        />
                        <van-tag type="primary">{{info.activity_status_note}}</van-tag>
                    </template>
                </van-cell>
                <van-cell title-class="img-cell-title">
                    <template slot="title">
                        <van-image
                                width="2.5rem"
                                height="1.8rem"
                                :src="info.banner_image"
                                fit="cover"
                        />
                        <div class="text-wrap">
                            <p class="t1 text-grey font-16 clamp-2">{{info.activity_name}}</p>
                            <p class="t2 text-base font-14 clamp-2">{{info.start_time}} 开始</p>
                            <p class="t3 text-grey font-14">{{info.address}}</p>
                        </div>
                        <p class="img-cell-status"></p>
                    </template>
                </van-cell>
                <van-cell>
                    <template slot="value">
                        <van-icon name="phone"/>
                        <span>联系Ta</span>
                    </template>
                </van-cell>
            </van-cell-group>

        </div>
    </div>
</template>

<script>
    import {Cell, CellGroup,Tag,Button} from "vant";
    import {get_ticket_detail,activity_detail} from "@/request/api";

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
            [Button.name]: Button,
        },
        data() {
            return {
                info: false,
                id: 0,
				activity: {}
            };
        },
        mounted: function () {
            this.id = this.$route.query.id;
            this.loadData();
        },
        methods: {
			toActivity(){
				this.$router.push({path:'/activity',query:{id:this.info.activity_id}})
			},
			initData() {
				activity_detail({
					activity_id: this.info.activity_id,
					member_id: 0,
					superior: 0
				}).then(res => {
					this.activity = res.data
				}).catch(err => {})
			
			},
            loadData() {
                if (this.id <= 0) {
                    return;
                }
                let param = {id: this.id};
                get_ticket_detail(param).then(res => {
                    this.info = res.data;
					this.initData()
                }).catch(error => {
                    this.$toast.fail("获取订单数据失败");
                });
            },
            toPay(){
                this.$router.push({path: "/activity/enroll/pay",query:{ticket_id:this.info.id,card_name:this.info.card_info.card_name}});
            }
        }
    };
</script>

<style lang="less">
    .moremessage-page {
        .cell-top {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
            .van-cell__title {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                .van-image {
                    margin-right: 5px;
                }
            }
        }
        .flex-5 {
            flex: 5;
        }
        .img-cell-title {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            .text-wrap {
                max-width: 180px;
                display: flex;
                flex-flow: column nowrap;
                margin: 0 5px;

            }
            .img-cell-status {
                color: green;
            }
        }
    }
</style>